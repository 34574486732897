import React from "react";
import SEO from "../components/seo"
import Layout from "../components/layout";

const Ueber = () => {
    return (
        <Layout>
            <SEO title="Impressum" />
            <div className="textPage">
                <h1>Impressum.</h1>
                <div className="textPageInfo" style={{textAlign: "left"}}>
                    <h3>Honigprodukte Maierhofer,<br/>Catering Maierhofer</h3>
                    <h4>Name</h4>
                    <p>Anna Maierhofer</p>
                    <h4>geografische Anschrift</h4>
                    <p>Halbenrain 188, 8492 Halbenrain</p>
                    <h4>Unternehmensgegenstand</h4>
                    <p>Handel und Verkauf von Lebensmittel, Catering</p>
                    <h4>Kontaktdaten</h4>
                    <a className="link" href="tel:+436645726615">Telefon: +43(0)664 5726615</a>
                    <p>Email: office@honigprodukte-maierhofer.at</p>
                    <h4>Mitgliedschaft bei der Wirtschaftskammerorganisation</h4>
                    <p>Mitglied der WKÖ</p>
                    <h4>anwendbare Rechtsvorschriften und Zugang dazu</h4>
                    <p>Gewerbeordnung:</p>
                    <a className="link" href="https://www.ris.bka.gv.at" target="_blank">www.ris.bka.gv.at</a>
                    <h4>Aufsichtsbehörde</h4>
                    <p>Bezirkshauptmannschaft Südoststeiermark</p>
                </div>
            </div>
        </Layout>
    )
}

export default Ueber